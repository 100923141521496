import qs from "qs";
import {request} from "@/core/services/Request";
import Error from "@/core/services/Error";

const DEFAULT_FORM_STATE = {
    title: null,
    rate: null,
    c_gst: 0,
    s_gst: 0,
    _method: 'post',
};

export default {
    data() {
        return {
            operation: null,
            operationTitle: 'Rooms',
            loading: false,
            formFields: {...DEFAULT_FORM_STATE},
            formErrors: new Error({}),
        }
    },
    methods: {
        async handleSubmitOperation() {
            this.formErrors = new Error({})
            try {
                const response = await request({
                    url: this.formFields.id ? 'rooms/update' : 'rooms/create',
                    method: 'post',
                    data: this.formFields,
                })
                if (this.formFields.id) {
                    this.$global.itemUpdated()
                } else {
                    this.$global.itemAdded()
                }
                this.handleOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }
                this.$global.handleServerError(error)
            }
        },
        async handleEditOperation(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/rooms/detail/${id}`,
                })
                const {data} = response.data
                this.formFields = {
                    ...data,
                    property_id: data.property ? data.property.id : null
                }
            } catch (e) {
                this.$global.itemEditFails()
                this.formFields = {...DEFAULT_FORM_STATE}
            }
        },
        async handleDeleteOperation(id) {
            let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
                primaryBtnText: 'Yes',
                secondaryBtnText: 'No'
            });
            if (deleteResponse) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/rooms/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.$global.itemDeleted()
                } catch (errors) {
                    this.$global.handleServerError(errors)
                }
            }
        },
        handleOperationClose() {
            this.formFields = {...DEFAULT_FORM_STATE}
            this.$router.push({name: "room"})
        },
    },
}
